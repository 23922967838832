/* Copyright 2020, 2021 Paratus Ltd. All rights reserved. */

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css'; // Import precompiled Bootstrap css

import $ from 'jquery';
import '../css/styles.scss';

// ----------------------------------------------------------------------------
// jQuery handlers

$(function() {
    setup_form_validation();
});

// Parallax scrolling
const scrollingSpeed = 2; // parallax scrolling background image is 200% height of containing div

function setParallaxOffset(image, windowOffset) {
}

var onScroll = function(){
    const windowTop = $(window).scrollTop();
    const windowHeight = $(window).height();
    const windowBottom = windowTop + windowHeight;
    $('.pt_image_section').each(function() {
        const imageTop = $(this).offset().top;
        const imageHeight = $(this).height();
        const imageBottom = imageTop + imageHeight;
        if (windowBottom >= imageTop && windowTop <= imageBottom)
        {
            // This fraction starts at 0 when imageTop = windowBottom, and steadily increases to 1 when imageBottom = windowTop
            const progressThroughScroll = (windowBottom - imageTop) / (imageHeight + windowHeight);
            const offset = Math.round((progressThroughScroll - 1) * (scrollingSpeed - 1) * imageHeight);
            $(this).css("background-position-y", offset+"px");
        }
    });
};

$(window).scroll(onScroll);

onScroll();


// Form handling

var setup_form_validation = function() {
    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    var forms = document.querySelectorAll('.needs-validation');

    // Loop over them and prevent submission
    Array.prototype.slice.call(forms)
        .forEach(function (form) {
            form.addEventListener('submit', function (event) {
                if (event.submitter.value == "save-questionnaire") {
                    // For the new client questionnaire form specifically.
                    return;
                }
                if (!form.checkValidity()) {
                    event.preventDefault();
                    event.stopPropagation();
                }
                form.classList.add('was-validated');
            }, false);
        });
};

var validate_element = function() {
    var elem = $(this);
    if (elem[0].checkValidity()) {
        elem.addClass('is-valid');
        elem.removeClass('is-invalid');
        elem.removeClass('is-server-invalid');
    }
    else {
        elem.addClass('is-invalid');
        elem.removeClass('is-valid');
    }
};

var last_group; // stores the last .form-group element that last had a focusout event

$('.form-group').focusout(function() {
    // When a form-group gets a focusout event, record the form-group element so that we can check whether the
    // element that gets focus next is outside of this group or not
    last_group = $(this);
});


$('.form-control, .form-check-input').focus(function(){
    // When a form element gets focus, check whether we've moved outside of last_group
    if (last_group) {
        // validate all the children of last_group if it no longer has focus
        if (last_group.find($(this)).length == 0) {
            last_group.find("input").each(validate_element);
            last_group.find("textarea").each(validate_element);
        }
        last_group = undefined;
    }
});

// Date pickers

$('#dateInput').change(event => {
    window.location.search = $.query.set('date', event.target.value);
});

// EOF
