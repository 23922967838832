/* Copyright 2020, 2021 Paratus Ltd. All rights reserved. */

import 'common/common';

import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle';

// ----------------------------------------------------------------------------
// jQuery handlers

// Bio pages
$('.pt_bio_expand').click(function() {
    $(this).parents('.pt_bio').find('.pt_bio_contents').slideToggle(200, "swing");
});


// Partner pages
$('.pt_partner_expand').click(function() {
    $(this).parents('.pt_partner').find('.pt_partner_contents').slideToggle(200, "swing");
});

$(function () {
    const requestQuoteInput = $('#requestQuote');
    function updateFields() {
        if (requestQuoteInput.is(":checked")) {
            $("#quoteFields").show();
            $("#quoteFields input").attr("required", "");
        } else {
            $("#quoteFields").hide();
            $("#quoteFields input").removeAttr("required");
        }
    }
    updateFields();
    requestQuoteInput.change(updateFields);
    $('[data-toggle="tooltip"]').tooltip()
});

// EOF
